import http from "./httpService";
import baseUrl from "./baseUrl";

export function postContact(values){
    console.log(values)
    return http.post("https://admin.codebreakers.quest/Contact/contactus/",{
        name: values.name,
        company: values.company,
        email: values.email,
        phone: values.phone,
        message: values.messages
    })
    .then(response => {
        if (response.status == 201) {
            // toast.success("Please Check your email for varification",{duration: 5000});
            // history.push('/sign-in', Dashbaord)
        }
    })
    .catch((err) => {
        // toast.error("Signup error!! Please check your email or password")
        console.log("AXIOS ERROR: ", err);
    })
}