import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";
import TitleSection from "../Titlesection/TitleSection";
const About = ({ sectionSpace, about, work, tech }) => {
  console.log(about)
  return (
    <Fragment>
      <section
        id="about-section"
        className={`about-section ${sectionSpace} clearfix`}
      >
        <Container>
        <TitleSection spacer="mb-100" textAlign="text-center">
              <h2 className="title-text mb-10">Our Expertise</h2>
            </TitleSection>
          {work.map((work,index)=>(
            index%2 != 0?
            <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div 
                className="about-content" 
                data-aos="fade-right"
                data-aos-delay="200"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                  {/* <p className="mb-60">{paragraph2}</p> */}
                </div>
              </Col>
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-left"
                    data-aos-delay="400"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          :
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>

                </div>
              </Col>

              <Col lg="6" md="8">
                <div 
                className="about-content" 
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Expertise</span>
                    <h2 className="feature-item-title mb-0">
                      {work.sevices_title}
                      {/* <span>{span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                  {/* <p className="mb-60">{paragraph2}</p> */}

                </div>
              </Col>
            </Row>
          </div>
          ))}
        </Container>
        <Container>
             <TitleSection spacer="mb-100" textAlign="text-center">
                <h2 className="title-text mb-10">We Use Latest Technologies</h2>
            </TitleSection>
            <div className="sec-ptb-100">
              <div
              data-aos="fade-right"
              data-aos-delay="600"
              >
                <Col className="content-left">
                   <div textAlign="text-center" >
                    <h4 className="title-text mb-10">Frontend framework and Libraries</h4>
                    <Row lg={2} md={6} >
                      {tech.slice(4,11).map(tech=>(
                        <Card sm={12} className="card-size">
                        <CardImg className="img-size" src={tech.logo_image}/>
                        <CardBody className="card-text">
                          <CardTitle>{tech.platform_name}</CardTitle>
                        </CardBody>
                      </Card>
                      ))}
                    </Row>
                    
                   </div>
                </Col>
              </div>
              <div
              data-aos="fade-left"
              data-aos-delay="800"
              >
                <Col className="content-right">
                   <div >
                    <h4 className="title-text mb-10">Server Side Framework and Libraries</h4>
                    <Row lg={2} sm={12}>
                    {tech.slice(0,4).map(tech=>(
                        <Card sm={12} className="card-size">
                        <CardImg className="img-size" src={tech.logo_image}/>
                        <CardBody className="card-text">
                          <CardTitle>{tech.platform_name}</CardTitle>
                        </CardBody>
                      </Card>
                      ))}
                    </Row>
                   </div>
                </Col>
              </div>
              <div
              data-aos="fade-right"
              data-aos-delay="1000"
              >
                 <Col className="content-left">
                   <div textAlign="text-center" >
                    <h4 className="title-text mb-10">Cloud Server and Hosting</h4>
                    <Row lg={2} md={6} >
                      {tech.slice(11,14).map(tech=>(
                        <Card sm={12} className="card-size">
                        <CardImg className="img-size" src={tech.logo_image}/>
                        <CardBody className="card-text">
                          <CardTitle>{tech.platform_name}</CardTitle>
                        </CardBody>
                      </Card>
                      ))}
                    </Row>
                    
                   </div>
                </Col>
              </div>
            </div>
        </Container>
        <Container>
           <TitleSection spacer="mb-100" textAlign="text-center">
              <h2 className="title-text mb-10">Why with us?</h2>
            </TitleSection>
            <div className="sec-ptb-100">
              <Row className="justify-content-center">
                <Col sm="12" md="6" lg="6">
                <div 
                  className="container container-elements"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                    <img src="/assets/images/expertise/1.png">
                    </img>
                    <h4>Experences</h4>
                    <p>We have vast qualified engineers with good experiances in tech industry. We always focus to bring products to help customer in market.</p>

                  </div>
                 
                </Col>
                <Col sm="12" md="6" lg="6">
                  <div 
                  className="container container-elements"
                  data-aos="fade-left"
                  data-aos-delay="400"
                  >
                    <img src="/assets/images/expertise/2.png">
                    </img>
                    <h4>Full-Cycle Development Services</h4>
                    <p>We try to engage with our clients from brainstorm to maintance. Oue software life cycle maintain the software development process.</p>

                  </div>
                 
                </Col>
                <Col sm="12" md="6" lg="6">
                  <div 
                  className="container container-elements"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  >
                    <img src="/assets/images/expertise/3.png">
                    </img>
                    <h4>Agile Methodology</h4>
                    <p>Agile methodologu ensure the development runs smoothly which help both customer and our engineering teams complete any task better.</p>

                  </div>
                 
                </Col>
                <Col sm="12" md="6" lg="6">
                  <div 
                  className="container container-elements"
                  data-aos="fade-left"
                  data-aos-delay="400"
                  >
                    <img src="/assets/images/expertise/4.png">
                    </img>
                    <h4>Transparent Process</h4>
                    <p>To achieve our goal and customer staisfaction, we ensure full transparent process.</p>

                  </div>
                 
                </Col>

              </Row>

            </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default About;
