
import { Link } from "react-router-dom";
import React, { Fragment, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";

const BreadCrumbOne = ({ title, description,image }) => {

  // const scenElement = useRef(null);

  // useEffect(() => {
  //   const parallaxInstance = new Parallax(scenElement.current, {
  //     relativeInput: true,
  //   });
  //   parallaxInstance.enable();
  //   return () => parallaxInstance.disable();
  // }, []);
  // return (
  //   <Fragment>
  //     <section
  //       id="banner-section"
  //       className="banner-section bg-light-gray clearfix"
  //     >
  //       <div className="sass-banner-2 clearfix">
  //         <Container>
  //           <Row className="justify-content-lg-between justify-content-md-center">
  //             <Col lg="5" md="8" xs="12">
  //             <div className="container">
  //          <div className="row justify-content-lg-start justify-content-md-center">
  //            <div className="">
  //              <div className="breadcrumb-content">
  //                <div className="section-title">
  //                  <h2 className="title-text mb-15">{title}</h2>
  //                  <p className="paragraph-text mb-0">
  //                    {description}
  //                  </p>
  //                </div>
  //              </div>
  //            </div>
  //          </div>
  //        </div>
  //               {/* <!-- show on mobile device - start --> */}
  //               {/* <div className="mobile-banner-image d-none">
  //                 <img
  //                   src="assets/images/banner/background-1.png"
  //                   alt="image_not_found"
  //                 />
  //               </div> */}
  //               {/* <!-- show on mobile device - end --> */}
  //               {/* <div className="banner-content">
  //                 {banner.map((banner)=>(<>
  //                   <h2 className="title-text mb-30">
  //                   {banner.title}
  //                 </h2>
  //                 <p >
  //                 {banner.short_description}
  //                 </p>
  //                 </>))}

  //               </div> */}
  //             </Col>

  //             <Col lg="6" md="8" xs="12">
  //               <div
  //                 className="banner-item-image scene"
  //                 id="scene"
  //                 ref={scenElement}
  //               >
  //                 <span className="bg-image" data-aos="zoom-in">
  //                   <img
  //                     src={image}
  //                     alt="image_not_found"
  //                   />
  //                 </span>
  //               </div>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </div>
  //     </section>
  //   </Fragment>
  // );
  return (
    <Fragment>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section mma-container clearfix"
        // style={{
        //   backgroundImage: `url('assets/images/breadcrumb/bg-image-1.jpg')`,
        // }}
      >
        <div className="">
        <div className="sass-banner-2 clearfix">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="5" md="8" xs="12">
              <div className="container">
          <div className="row justify-content-lg-start justify-content-md-center">
          <div className="breadcrumb-content">
                <div className="section-title">
                  <h2 className="title-text mb-15">{title}</h2>
                  <p className="paragraph-text mb-0">
                    {description}
                  </p>
                </div>
              </div>
          </div>
        </div>
              </Col>
              <Col lg="6" md="8" xs="12">
              <div className="" data-aos="fade-up" data-aos-delay="100">
              <img
              src={image}
              alt=""
            />
            
          </div>
              </Col>
            </Row>
          </Container>
        </div>
        </div>
       

       

      </section>
    </Fragment>
  );
};

export default BreadCrumbOne;
