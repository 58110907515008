import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { getPortfolio } from "../../service/portfolioService";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const FeatureSeven = ({ sectionSpace,  }) => {
  const[portfolio, setPortfolio] = useState([])
  const [screen,setScreen] = useState(false)

  useEffect(()=>{
    const checkScreen = window.matchMedia("(max-width: 800px)").matches
    setScreen(checkScreen)
    getProjects()
  },[]);
 
  const getProjects = async()=>{
    try{
      const response = await getPortfolio()
      setPortfolio(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            {portfolio.map((portfolio,index)=>(
             screen==false? (index%2 != 0?
              <Row className="content-space justify-content-lg-between justify-content-md-center">
                <Col lg="6" md="6" >
                <div 
                className="feature-content"
                data-aos="fade-right"
                data-aos-delay="400"
                >
                  <div className="section-title mb-30">
                    {/* <span className="sub-title mb-15">Services</span> */}
                    <Route path='http://62.72.1.214:3000/'>
                    <h2 className="title-text mb-0">
                      {portfolio.title}
                      {/* <span>{span}</span> */}
                    </h2>
                    </Route>
                  </div>

                  <p className="mb-30">{portfolio.title_description}</p>
                </div>
              </Col>
              <Col lg="6" md="6" >
                <div className="feature-image-7 float-right">
                <span
                     className="phone-image"
                     data-aos="fade-left"
                     data-aos-delay="200"
                  >
                    <img
                      src={portfolio.demo_image}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
            :
            <Row className=" content-space justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="6">
                <div className="feature-image-7 float-right">
                <span
                     className="phone-image"
                     data-aos="fade-right"
                     data-aos-delay="200"
                  >
                    <img
                      src={portfolio.demo_image}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            <Col lg="6" md="6">
                <div 
                className="feature-content"
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-30">
                    {/* <span className="sub-title mb-15">Services</span> */}
                    <h2 className="title-text mb-0">
                      <a className="text-black" href='http://62.72.1.214:3000/'>{portfolio.title}</a>
                      {/* <span>{span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">{portfolio.title_description}</p>
                </div>
              </Col>
            </Row>)
            :
            (
              <Row className=" content-space justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-image-7 float-right">
                <span
                     className="phone-image"
                     data-aos="fade-right"
                     data-aos-delay="200"
                  >
                    <img
                      src={portfolio.demo_image}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            <Col lg="6" md="8">
                <div 
                className="feature-content"
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-30">
                    {/* <span className="sub-title mb-15">Services</span> */}
                    <h2 className="title-text mb-0">
                      {portfolio.title}
                      {/* <span>{span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">{portfolio.title_description}</p>
                </div>
              </Col>
            </Row> 
            )
            )
            )
            }
            
          </div>

        </Container>
      </section>
    </Fragment>
  );
};

export default FeatureSeven;
