import React from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";
const Footer = ({ sectionSpace }) => {
  return (
    <footer
      id="footer-section"
      className={`footer-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url(/assets/images/footer-bg-1.png)` }}
    >
      <div
        className="footer-content mb-80 clearfix"
        data-aos="fade-up"
        data-aos-duration="450"
        data-aos-delay="200"
      >
        <Container>
          <Row className="footer-contents">
            <Col lg="3" md="6">
              <div className="about-content">
                <div className="brand-logo mb-30">
                  <Link to="/" className="brand-link">
                    <img
                      src="/assets/images/logo/logo-1.png"
                      alt="logo_not_found"
                    />
                  </Link>
                </div>
                <p className="mb-30">
                  Codebreakers is a software company. We provide top notch software solution for business.We have dedicated engineers who work to ensure quality software solutions for business.
                </p>
                <div className="social-links ul-li clearfix">
                  <ul className="clearfix">
                    <li>
                      <Link to="https://www.facebook.com/profile.php?id=100084821083274">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/company/codebreakersbd">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">Bangladesh</h3>
                <ul className="clearfix">
                <li>
                     <Link>
                     <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                        Hur palace, North Kattali, Colonel Hat, Chattogram
                      </span>
                     </Link>
                  </li>
                  <li>
                     <Link>
                     <small className="icon">
                        <i className="uil uil-phone-pause"></i>
                      </small>
                      <span className="info-text">+880-1748987607</span>
                     </Link>
                  </li>
                  <li>
                    <Link>
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="info-text">info@codebreakers.quest</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">New York,USA</h3>
                <ul className="clearfix">
                <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                        28 Green Tower, water stone street,New York City, USA
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-phone-pause"></i>
                      </small>
                      <span className="info-text">+46 991 257 20</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="info-text">info@codebreakers.quest</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">California, USA</h3>
                <ul className="clearfix">
                <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                      30300 antelope rd, Menifee, California
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-phone-pause"></i>
                      </small>
                      <span className="info-text">+46 991 257 00</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="info-text">info@codebreakers.quest</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="footer-bottom clearfix">
          <Row>
            <Col md="12">
              <p className="copyright-text mb-0 text-center">
                Copyright @ 2022{" "}
                <a
                  href="https://www.facebook.com/profile.php?id=100084821083274"
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="author-link"
                >
                  CodeBreakers
                </a>{" "}
                all right reserved.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
