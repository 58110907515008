import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';


const Jobs = ({career})=> {
    return (  
        <Fragment>
            <div className='headline'>
                {career==0?<h4>No Job Openings</h4>: <h4>Current Job Openings</h4>}
            </div>
            {
                career==0?<></>:
                <div className='table'>
             <Table striped bordered hover center>
                <thead>
                    <tr>
                    <th>Job Title</th>
                    <th>Location</th>
                    <th>Department</th>
                    <th>Emplyement Type</th>
                    <th>View Details</th>
                    </tr>
                </thead>
                <tbody>
                    {career.map((career)=>(
                        <tr>
                        <td>{career.title_one}</td>
                        <td>{career.location}</td>
                        <td>{career.department}</td>
                        <td>{career.employee_type}</td>
                        <td> <Link to={`/job-details/${career.id}`} className="button">View</Link> </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
             </div>
            }
        </Fragment>
    );
}

export default Jobs;