import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";

import { Map } from "../../components/Map";
import { getAbout } from "../../service/aboutService";
import { About } from "../../components/About";
const ContactPage = () => {
  const [about, setAbout] = useState([])
  const description = "We are wre excited to talk with you. you can easily fix a meeting with your available time. CodeBreaker is ready to give you support and answers to your query."

  useEffect(()=>{
    getAboutUs()
  },[])

  const getAboutUs = async()=>{
    try{
      const response = await getAbout()
      setAbout(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <PageTitle
        PageTitle="CodeBreakers-Contact Us"
        favicon="/assets/images/logo/tab-logo.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-8"
        sectionSpace="sec-ptb-160 pb-0"
      >
        <main>
          <BreadCrumbOne
            title="contact with us"
            description={description}
            image="/assets/images/breadcrumb/bg-3.png"
          />
          <Map about ={about} />
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default ContactPage;
