import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BannerEight } from "../../components/Banner";
import { SoftwareSectionTwo } from "../../components/Software";

import BlogSlider from "../../components/BLogSlider/BlogSlider";
import { getIndex } from "../../service/indexService";
import { useState } from "react";
import { useEffect } from "react";
import { getOffer, getSubOffer } from '../../service/offerService';
import { getWork } from "../../service/workService";
import { getBlog } from "../../service/blogService";
import FeatureEight from "../../components/Features/FeatureEight";
const HomePageEight = () => {
  const [banner, setBanner] = useState([]);
  const [offer, setOffer] = useState([]);
  const [suboffer, setSubOffer] = useState([]);
  const [work, setWork] = useState([]);
  const [blog, setBlog] = useState([]);

  useEffect(()=>{
    getBanners()
    getOffers()
    getSubOffers()
    getWorkField()
    getBlogs()
  },[]);
   const getBanners = async ()=>{
    try{
      const response = await getIndex()
      setBanner(response.data)
    }
    catch(err){}
    
  }
  const getOffers = async ()=>{
    try{
      const response = await getOffer()
      setOffer(response.data)
    }
    catch(err){}
  }
  const getSubOffers = async ()=>{
    try{
      const response = await getSubOffer()
      setSubOffer(response.data)
    }
    catch(err){}
  }
  const getWorkField = async ()=>{
    try{
      const response = await getWork()
      setWork(response.data)
    }
    catch(err){}
  }
  const getBlogs = async ()=>{
    try{
      const response = await getBlog()
      setBlog(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <PageTitle
        PageTitle="CodeBreakers"
        favicon="/assets/images/logo/tab-logo.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-8"
        sectionSpace="sec-ptb-160 pb-0"
      >
        <main>
          <BannerEight banner={banner} sectionSpace="mb-60" />
          <FeatureEight work={work} offer={offer} suboffer={suboffer} sectionSpace="sec-ptb-160"/>
          <SoftwareSectionTwo sectionSpace="sec-ptb-160" />
          <BlogSlider blog={blog} sectionSpace="sec-ptb-160 pb-0" />
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default HomePageEight;
