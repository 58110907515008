import React, { Fragment, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import featureDataOne from "../../data/feature/feature-one.json";
import { useEffect } from "react";
const [{  span }] = featureDataOne;

const FeatureEight = ({ sectionSpace , offer, suboffer, work}) => {
  const [screen,setScreen] = useState(false)
  let bg=6;

  useEffect(()=>{
    const checkScreen = window.matchMedia("(max-width: 800px)").matches
    setScreen(checkScreen)
  });
  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              {offer.map(offer=>(
                <Col lg="5" md="8" xs="12">
                <div className="feature-image-2 text-center" data-aos="fade-right" data-aos-delay="200">
                  <span className="item-image">
                    <img
                      src={offer.offer_image}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
              ))}

              <Col lg="6" md="8" xs="12">
                <div className="feature-content p-0" data-aos="fade-left" data-aos-delay="400">
                {offer.map((offer, index)=>(
                  <>
                  <h2 className="feature-item-title">
                    <span>{offer.offer_title}</span>
                  </h2>
                  <p className="mb-0">
                  {offer.offer_description}
                  </p>
                  </>
                ))}
                  
                  <div className="service-list ul-li clearfix">
                    <ul className="clearfix">
                      {suboffer.map(suboffer=>(
                         <li>
                         <div
                           className="item-icon"
                           style={{
                             backgroundImage: `url('assets/images/icons/bg-${bg++}.png')`,
                           }}
                         >
                           <i className="uil uil-lightbulb-alt"></i>
                         </div>
                         <div className="item-content">
                           <h3 className="item-title mb-15">
                             {suboffer.suboffer_title}
                           </h3>
                           <p className="mb-0">
                             {suboffer.suboffer_description}
                           </p>
                         </div>
                       </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {work.map((work,index)=>(
            screen==false?(index%2 !== 0?
            <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-right"
                data-aos-delay="200"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                  {/* <p className="mb-60">{paragraph2}</p> */}
                </div>
              </Col>
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-left"
                    data-aos-delay="400"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          :
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                </div>
              </Col>
            </Row>
          </div>)
          :
          (
            <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="10">
                <div className="feature-image-8">
                  <span
                    className="phone-image"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <img
                      src={work.services_banner}
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div 
                className="feature-content" 
                data-aos="fade-left"
                data-aos-delay="400"
                >
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Our Services</span>
                    <h2 className="title-text mb-0">
                      {work.sevices_title}
                    </h2>
                  </div>

                  <p className="mb-30">{work.services_description}</p>
                </div>
              </Col>
            </Row>
          </div> 
          )
          ))}
        </Container>
      </section>
    </Fragment>
  );
};

export default FeatureEight;
