import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { About } from "../../components/About";
import { getAbout } from "../../service/aboutService";
import { getWork } from "../../service/workService";
import { getTech } from "../../service/techService";

const AboutPage = () => {
  const [about, setAbout] = useState([])
  const [work, setWork] = useState([])
  const [tech, setTech] = useState([])
  const description = "We have different experts in specific language and their frameworks. Our engineers are familiar with the most updated technologies and tools which are available. In some existing projects, our experts are capable of doing work with compatible versions of your system."

  useEffect(()=>{
    getAboutUs()
    getWorkField()
    getExpertise()
  },[])

  const getAboutUs = async()=>{
    try{
      const response = await getAbout()
      setAbout(response.data)
    }
    catch(err){}
  }
  const getWorkField = async ()=>{
    try{
      const response = await getWork()
      setWork(response.data)
    }
    catch(err){}
  }
  const getExpertise = async ()=>{
    try{
      const response = await getTech()
      setTech(response.data)
    }
    catch(err){}
  }
  return (
    <Fragment>
      <PageTitle
        PageTitle="CodeBreakers-Our Expertise"
        favicon="/assets/images/logo/tab-logo.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-8"
        sectionSpace="sec-ptb-160 pb-0"
      >
        <main>
          <BreadCrumbOne
            title="We are More then a team"
            description={description}
            image="/assets/images/breadcrumb/bg-1.png"
          />
          <About tech={tech} work={work} about={about} sectionSpace="sec-pb-80" />
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default AboutPage;
