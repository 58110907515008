import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
const SideBar = ({ sidebarIsOpen, handleSidebar }) => {
  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>

          <ul className="menu-list mb-60 list-unstyled components clearfix">
            <li className="menu-item-has-child active">
              <Link to="/" id="toggler0">
                Home
              </Link>
            </li>
            <li className="menu-item-has-child">
              <Link to="our-works" id="toggler1">
                Our Works
              </Link>
            </li>
            <li className="menu-item-has-child">
              <Link to="contact-us" id="toggler2">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="our-expertise">Our Expertise</Link>
            </li>
            <li className="menu-item-has-child">
              <Link to="career" id="toggler3">
                Career
              </Link>
            </li>
          </ul>

          <div className="contact-info ul-li-block mb-60 clearfix">
            <h2 className="sidebar-title mb-30">contact info</h2>
            <ul className="clearfix">
            <li>
                <span className="icon">
                  <i className="uil uil-map-pin"></i>
                </span>
                New York City, USA
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-map-marker"></i>
                </span>
                28 Green Tower, Street Name
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-envelope-alt"></i>
                </span>
                www.yourwebsite.com
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-phone"></i>
                </span>
                +448 955 744 333
              </li>
            </ul>
          </div>

          <div className="social-links ul-li clearfix">
            <h2 className="sidebar-title mb-30">follow us</h2>
            <ul className="clearfix">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100084821083274">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              {/* <li>
                <a>
                <i className="fab fa-twitter"></i>
                </a>
              </li> */}
              <li>
              <a href="https://www.linkedin.com/company/codebreakersbd">
                <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
