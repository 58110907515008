import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { VideoModal } from "../../Modal";

const BlogDetails = ({blog}) => {
  console.log(blog)
  return (
    <Fragment>
      <div className="blog-details-content pb-0 clearfix">  
        <p className="mb-0" dangerouslySetInnerHTML={{__html:blog.description}}>
    
        </p>
      </div>
    </Fragment>
  );
};

export default BlogDetails;
