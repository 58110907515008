import http from "./httpService";
import baseUrl from "./baseUrl";



export function getOffer(){
    return http.get("https://admin.codebreakers.quest/Portfolio/ouroffer/")
}
export function getSubOffer(){
    return http.get("https://admin.codebreakers.quest/Portfolio/suboffer/")
}