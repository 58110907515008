import React, { Fragment, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";
const BannerEight = ({banner}) => {
  const scenElement = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);
  return (
    <Fragment>
      <section
        id="banner-section"
        className="banner-section bg-light-gray clearfix"
      >
        <div className="sass-banner-2 clearfix">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="8" xs="12">
                {/* <!-- show on mobile device - start --> */}
                <div className="mobile-banner-image d-none">
                  <img
                    src="assets/images/banner/background-1.png"
                    alt="image_not_found"
                  />
                </div>
                {/* <!-- show on mobile device - end --> */}
                <div className="banner-content">
                  {banner.map((banner)=>(<>
                    <h2 className="title-text mb-30">
                    {banner.title}
                  </h2>
                  <p >
                  {banner.short_description}
                  </p>
                  </>))}

                </div>
              </Col>

              <Col lg="6" md="8" xs="12">
                <div
                  className="banner-item-image scene"
                  id="scene"
                  ref={scenElement}
                >
                  <span className="bg-image" data-aos="zoom-in">
                    <img
                      src="assets/images/banner/background-1.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Fragment>
  );
};

export default BannerEight;
