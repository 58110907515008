import React, { Fragment, useEffect, useState, Component } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { BlogDetailsTwo } from "../../components/Blogs";
import { getBlogDetails } from "../../service/blogService";
import http from "../../service/httpService";
import axios from "axios";
import { API_URL } from "../../App";
axios.defaults.withCredentials = true;

class BlogDetailsTwoPage extends Component {
  constructor(props){
    super(props);
    this.state ={
      blogDetails:[]
    }
  }
  async componentDidMount(){
      try{
      const {data:blogDetails} = await http.get(`${API_URL}/Blog/Blog/${this.props.match.params.id}`)
      this.setState({blogDetails});
      
    }
    catch(err){
      console.log(err)
    }
  }

  render(){
    const {blogDetails} = this.state
    console.log(blogDetails)
    return (
      <Fragment>
        <PageTitle
          PageTitle="Blog Details With Sidebar - Appal - Mobile App Leanding Page"
          favicon="/assets/images/logo/tab-logo.png"
        />
        <LayoutFluid
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0"
        >
          <main>
            <BlogDetailsTwo blog={blogDetails}  sectionSpace="sec-ptb-160 pb-0" />
            {/* <BlogDetailsTwo  sectionSpace="sec-ptb-160 pb-0" /> */}
          </main>
        </LayoutFluid>
      </Fragment>
    );
  }
};

export default BlogDetailsTwoPage;
