import { react, Component } from "react";
import ApplyForm from "../../components/ApplyJob/applyForm";
import PageTitle from "../../components/utility";
import LayoutTwo from '../../layouts/LayoutTwo';
import BreadCrumbTwo from '../../components/BreadCrumb/BreadCrumbTwo';

class ApplyJob extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <>
            <PageTitle
             PageTitle="CodeBreakers-Apply Job"
             favicon="/assets/images/logo/tab-logo.png"
            />
            <LayoutTwo
            activeClass="active"
            wrapperClass="home-page-8"
            sectionSpace="sec-ptb-160 pb-0">
            <BreadCrumbTwo/>
            <ApplyForm name={this.props.match.params.position}></ApplyForm>


            </LayoutTwo>

            
            </>
        );
    }
}
export default ApplyJob;