import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { HomePageEight } from "./pages";
import { FeaturePageTwo } from "./pages";
import { AboutPage } from "./pages";
import { ContactPage } from "./pages";
import { ErrorPage } from "./pages";
import { BlogDetailsTwoPage } from "./pages";
import Career from "./pages/others/career";
import JobDetails from "./pages/others/job-details";
import ApplyJob from "../src/pages/others/applyJob"

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/scss/bootstrap.scss";
import "./assets/css/animate.css";
import "./assets/css/unicons.css";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.css";
import "./assets/scss/style.scss";
export const API_URL =  "https://admin.codebreakers.quest"

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter basename="/">
      <Route>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={HomePageEight} />
          <Route path="/blog-details/:id" component={BlogDetailsTwoPage} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/contact-us" component={ContactPage} />
          <Route path="/our-expertise" component={AboutPage} />
          <Route path="/career" component={Career} />
          <Route path="/job-details/:id" component={JobDetails} />
          <Route path="/:position/new-application" component={ApplyJob}></Route>
          <Route
            path="/our-works"
            component={FeaturePageTwo}
          />

          <Route component={ErrorPage}></Route>
        </Switch>
      </ScrollToTop>
      </Route>
    </BrowserRouter>
  );
}

export default App;
