import axios from "axios";
axios.defaults.baseURL = "https://admin.codebreakers.quest/"

axios.interceptors.response.use(null,error=>{
    const expextedError = 
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

    if(!expextedError){
        alert("An Unexpected error occured.");
    }

    return Promise.reject(error)
})

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}
