import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "./elements/Navigation";
import { Container, Row, Col } from "reactstrap";

const HeaderTwo = ({
  activeClass,
  btnType,
  whitekContent,
  logoWhite,
  handleSidebar,
}) => {
  const [scroll, setScroll] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(false);

  useEffect(() => {
    const Header = document.getElementById("header-section");
    setHeaderHeight(Header.offsetHeight);
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <header
      id="header-section"
      className={`header-section ${
        whitekContent ? whitekContent : "black-content"
      } sticky-header clearfix  ${
        scroll > headerHeight ? "stuck" : "not-stuck"
      }`}
    >
      <Container>
        <Row>
          {/* brand-logo - satrt */}
          <Col lg="12" sm="12">
            <div className="brand-logo-apply-form float-center">
              <Link to="/" className="brand-link">
                <img
                  src={logoWhite ? logoWhite : "/assets/images/logo/logo-1.png"}
                  alt="logo_not_found"
                />
              </Link>
            </div>
          </Col>
          {/* btns-group - end */}
        </Row>
      </Container>
    </header>
  );
};

export default HeaderTwo;
